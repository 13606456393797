import React, { useState, useEffect } from "react"
import { Waypoint } from "react-waypoint"
import { Transition } from "react-transition-group"
import ImageBlock from "./ImageBlock"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const Portrait = ({
  portrait,
  images,
  key,
  hasHover,
  mouseEnter,
  mouseLeave,
}) => {
  const [animate, setAnimate] = useState(0)

  const doAnimate = () => {
    setAnimate(true)
    console.log("entery")
  }
  const clearAnimate = () => {
    setAnimate(false)
    console.log("exit")
  }
  const handlePositionChange = () => {
    console.log("change")
  }
  useEffect(() => {
    console.log(animate)
    return () => {
      //cleanup
    }
  }, [animate])

  return (
    <Waypoint onEnter={doAnimate} onLeave={clearAnimate} key={key}>
      <article
        className={`portrait ${hasHover}`}
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
      >
        <Transition appear={animate} in={animate}>
          {state => (
            <article className={`portrait-inner anim--${state}`}>
              {portrait.imageId && (
                <ImageBlock
                  imageId={portrait.imageId}
                  image={images}
                ></ImageBlock>
              )}
              {portrait["Image Filename"] && (
                <img
                  src={`https://portraitsofleadership.org/data/${portrait["Image Filename"]}`}
                  alt={`${portrait.firstName} ${portrait.lastName}`}
                />
              )}
              {console.log(animate, state)}
              <h1>
                {portrait["First Name"]} {portrait["Last Name"]}
              </h1>
              <span className={"title"}>{portrait["Title"]}</span>
              <span className={"org"}>{portrait["Organization"]}</span>

              <OutboundLink
                href={portrait["URL"]}
                target="_blank"
                className="link"
              ></OutboundLink>
            </article>
          )}
        </Transition>
      </article>
    </Waypoint>
  )
}

export default Portrait
