import React, { useState, useEffect } from "react"
import { Link, useStaticQuery } from "gatsby"
import { Waypoint } from "react-waypoint"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Portrait from "../components/Portrait"
import Logo from "../images/hyperakt-logo.svg"
import HR from "../images/hr.svg"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const IndexPage = () => {
  const images = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "CJA-AubreyFox.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(relativePath: { eq: "DonorsChoose-CharlesBest.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const [globalHover, setGlobalHover] = useState("")
  const [isLoaded, setIsLoaded] = useState(false)
  const [portraitData, setPortraitData] = useState(null)
  const onHover = () => {
    setGlobalHover("hasHover")
  }
  const onUnHover = () => {
    setGlobalHover("")
  }
  useEffect(() => {
    console.log("effect 1")
    fetch(`https://portraitsofleadership.org/data/Portraits%20-%20Data.json`)
      .then(res => res.json())
      .then(
        result => {
          setPortraitData(result)
          setIsLoaded(true)
        }, // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        error => {
          console.log(error)
          setIsLoaded(false)
        }
      )
  }, [isLoaded])
  return (
    <Layout>
      <SEO title="Portraits of Leadership" />
      <div className="layout">
        <header className="intro">
          <div className="logo">
            <OutboundLink href="http://hyperakt.com" target="_blank">
              <Logo></Logo>
            </OutboundLink>
          </div>
          <div class="intro-content">
            <h1 className="siteTitle">Portraits of Leadership</h1>
            <HR className="separator"></HR>
            <p>
              Produced by purpose-driven design studio{" "}
              <OutboundLink href="http://hyperakt.com" target="_blank">
                Hyperakt
              </OutboundLink>
              , Portraits of Leadership is a new interview series that hands the
              mic to nonprofit and cultural executives around the nation. CEOs,
              Presidents, and Directors share an inside view as they guide some
              of the country's leading justice, education, health, and cultural
              organizations through the COVID-19 pandemic. Click on a portrait
              to get started! New interviews are published weekly.
              <br></br>
              <OutboundLink href="mailto:laura@hyperakt.com" target="_blank">
                Get in touch.
              </OutboundLink>
            </p>
          </div>
        </header>

        <div className="portraits">
          {portraitData && console.log(portraitData)}
          {portraitData &&
            portraitData.map((portrait, index) => (
              <React.Fragment key={`p-${index}`}>
                {portrait["Show"] != 0 && (
                  <Portrait
                    portrait={portrait}
                    images={images}
                    key={"portrait-" + index}
                    mouseEnter={onHover}
                    mouseLeave={onUnHover}
                    hasHover={globalHover}
                  ></Portrait>
                )}
              </React.Fragment>
            ))}
        </div>
      </div>
    </Layout>
  )
}
export default IndexPage
