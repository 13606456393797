import React from "react"
import Img from "gatsby-image"

const ImageBlock = ({ image, imageId, caption, overflow }) => {
  return (
    <div
      className={
        "image-block" + (overflow ? " overflow overflow-" + overflow : "")
      }
    >
      {console.log(image)}
      {image && <Img fluid={image[imageId].childImageSharp.fluid}></Img>}

      {/* <img
        src="https://placehold.it/623x460"
        className={"overflow overflow-" + overflow}
      ></img> */}
      {caption && (
        <figcaption>
          <span>{caption}</span>
        </figcaption>
      )}
    </div>
  )
}

export default ImageBlock
